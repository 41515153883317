<template>
  <div>
    <div style="display: flex;justify-content: center;  ">
      <el-upload drag
                 :limit=limitNum
                 :auto-upload="false"
                 accept=".xlsx"
                 :action="UploadUrl()" :on-remove="remove"
                 :before-upload="beforeUploadFile"
                 :on-change="fileChange"
                 :on-exceed="exceedFile"
                 :on-success="handleSuccess"
                 :on-error="handleError"
                 :file-list="fileList">
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <div class="el-upload__tip" slot="tip" >只能上传xlsx文件，且不超过10M</div>
      </el-upload>
    </div>
    <br/>
    <el-button size="small" type="primary" @click="uploadFile" :loading="loading" >立即上传</el-button>
    <br/>

    <el-card >
      <h6 v-if="invalidData.length!=0">无效数据</h6>
      <p style="text-align: center; color: red; font-size: 18px">{{msg}}</p>
      <el-table :data="invalidData" v-if="invalidData.length!=0" border stripe>
        <el-table-column type="index" label="#"></el-table-column>
        <el-table-column label="错误原因" prop="错误原因"></el-table-column>
        <el-table-column label="仓库" prop="仓库"></el-table-column>
        <el-table-column label="仓库分区" prop="仓库分区"></el-table-column>
        <el-table-column label="分类一" prop="分类一"></el-table-column>
        <el-table-column label="分类二" prop="分类二"></el-table-column>
        <el-table-column label="分类三" prop="分类三"></el-table-column>
        <el-table-column label="名称" prop="名称"></el-table-column>
        <el-table-column label="规格型号" prop="规格型号"></el-table-column>
        <el-table-column label="颜色" prop="颜色"></el-table-column>
        <el-table-column label="型号2" prop="型号2"></el-table-column>
        <el-table-column label="型号3" prop="型号3"></el-table-column>
        <el-table-column label="单位" prop="单位"></el-table-column>
        <el-table-column label="数量" prop="数量"></el-table-column>
        <el-table-column label="安全数量" prop="安全数量"></el-table-column>
        <el-table-column label="成本" prop="成本"></el-table-column>
        <el-table-column label="市场价" prop="市场价"></el-table-column>
        <el-table-column label="是否含税" prop="是否含税"></el-table-column>
        <el-table-column label="折扣价1" prop="折扣价1"></el-table-column>
        <el-table-column label="折扣价2" prop="折扣价2"></el-table-column>
        <el-table-column label="折扣价3" prop="折扣价3"></el-table-column>
        <el-table-column label="质量等级" prop="质量等级"></el-table-column>
        <el-table-column label="生产日期" prop="生产日期"></el-table-column>
        <el-table-column label="截至日期" prop="截至日期"></el-table-column>
        <el-table-column label="供应商" prop="供应商"></el-table-column>
        <el-table-column label="主图" prop="主图"></el-table-column>
        <el-table-column label="来源" prop="来源"></el-table-column>
        <el-table-column label="详情说明" prop="详情说明"></el-table-column>
        <el-table-column label="备注" prop="备注"></el-table-column>
      </el-table>
      <div class="updata">
        <el-button size="small" @click="addgo" :disabled="updatadisabled">取消提交</el-button>
        <el-button size="small" type="primary" @click="updataFile" :disabled="updatadisabled">确认提交</el-button>
      </div>
    </el-card>
  </div>
</template>

<script>
import XLSX from "xlsx";

export default {
  data() {
    return {
      limitNum: 1,  // 上传excell时，同时允许上传的最大数
      fileList: [],   // excel文件列表
      loading:false,
      updatadisabled:true,
      invalidData:[],
      msg:''
    }
  },
  methods:{
    // 文件超出个数限制时的钩子
    exceedFile(files, fileList) {
      this.$message.warning(`只能选择 ${this.limitNum} 个文件，当前共选择了 ${files.length + fileList.length} 个`);
    },
    // 文件状态改变时的钩子
    fileChange(file, fileList) {
      console.log(file.raw);
      this.fileList.push(file.raw) ;
      console.log(this.fileList);
    },
    // 上传文件之前的钩子, 参数为上传的文件,若返回 false 或者返回 Promise 且被 reject，则停止上传
    beforeUploadFile(file) {
      console.log('before upload');
      console.log(file);
      let extension = file.name.substring(file.name.lastIndexOf('.')+1);
      let size = file.size / 1024 / 1024;
      if(extension !== 'xlsx') {
        this.$message.warning('只能上传后缀是.xlsx的文件');
      }
      if(size > 10) {
        this.$message.warning('文件大小不得超过10M');
      }
    },
    // 文件上传成功时的钩子
    handleSuccess(res, file, fileList) {
      this.$message.success('文件上传成功');
    },
    // 文件上传失败时的钩子
    handleError(err, file, fileList) {
      this.$message.error('文件上传失败');
    },
    UploadUrl:function(){
      // 因为action参数是必填项，我们使用二次确认进行文件上传时，直接填上传文件的url会因为没有参数导致api报404，所以这里将action设置为一个返回为空的方法就行，避免抛错
      return ""
    },
    remove(){
      this.fileList=[]
    },
    //上传
    uploadFile() {
      if (this.fileList.length === 0){
        this.$message.warning('请上传文件');
      } else {
        this.loading=true
        let form = new FormData();
        form.append('filename', this.fileList[0]);
        form.append('old_filename', this.fileList[0].name);
        form.append('is_save', 0);
        this.$http.post('goods/preImport',form,{'Content-type': 'multipart/form-data'})
          .then(
            res=>{
              //无效数据
              console.log(res)
              if (res.data.code !== 200){
                this.$message.error(res.data.msg)
                this.loading=false
                this.msg=res.data.msg
              }else{
                this.invalidData=res.data.data.invalidData
                // console.log(res.data.dar)
                this.loading=false
                this.updatadisabled=false
                this.$message.success(res.data.msg)
                this.msg=res.data.msg
              }
            },err =>{
          });
        // this.$axios({
        //   method:"post",
        //   url: "http://ofd.store.com/adminapi/goods/preImport",
        //   headers:{
        //     'Content-type': 'multipart/form-data'
        //   },
        //   data:form
        // })

      }
    },
    //提交
    updataFile(){
      if (this.fileList.length === 0){
        this.$message.warning('请上传文件');
      } else {
        this.loading=true
        let form = new FormData();
        form.append('filename', this.fileList[0]);
        form.append('old_filename', this.fileList[0].name);
        form.append('is_save', 1);
        this.$http.post('goods/preImport',form,{'Content-type': 'multipart/form-data'})
          .then(
            res=>{
              if (res.data.code !== 200){
                this.$message.error(res.data.msg)
                this.loading=false
              }else {
                //无效数据
                res.data.invalidData
                //有效数据
                res.data.realData
                this.loading = false
                this.$message.success(res.data.msg)
                this.addgo()
              }
            },err =>{
            }
          );
        }
    },
    addgo(){
      this.$router.go(-1)
    },

  }
}
</script>

<style lang="less" scoped>
.el-upload__tip{
  text-align: center;
}
.el-card{
  margin-top: 30px;

}
.el-card h6{
  margin: 0;
  font-size: 24px;
  text-align: center;
  color: #606266;
  font-weight: 700;
}
.updata{
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
</style>
<!--    async uploadConfig(e) {-->
<!--      let formData = new FormData();-->
<!--      console.log(formData)-->
<!--      let data = JSON.stringify({-->
<!--        user: "username",-->
<!--        env: "dev"-->
<!--      })-->
<!--      console.log(data)-->
<!--      formData.append('file', e.target.files[0]);-->
<!--      formData.append('data', data);   // 上传文件的同时， 也可以上传其他数据-->
<!--      // console.log(formData)-->
<!--      // console.log(e.target.files[0])-->
<!--      // const {data:res} =await this.$http.post('goods/preImport',{filename:formData})-->
<!--      // if (res.code!=200) this.$message.error(res.msg)-->
<!--      // this.$message.success(res.msg)-->
<!--      let url = "http://ofd.store.com/adminapi/goods/preImport";-->
<!--      let config = {-->
<!--        headers:{'Content-Type':'multipart/form-data'}-->
<!--      };-->
<!--      this.$axios.post(url,formData, config).then(function (response) {-->
<!--        console.log(response.data)-->

<!--      })-->

<!--    }-->

<!--  }-->
<!--}-->

